import React from "react"
import { Link } from "gatsby"
import Layout from "components/site-structure/layout"
import SEOComponent from "components/seo"

const IndexPage = () => {
  return (
    <Layout>
      <SEOComponent title="Home" />

      <div className="columns is-centered">
        <div className="column is-one-third">
          <h1 className="title is-2 is-size-3-mobile has-text-centered">
            Willkommen bei Mölkky Online!
          </h1>
          <hr />
          <div className="buttons">
            <Link
              to={"/spielen/"}
              className="button is-warning is-fullwidth is-large"
            >
              <b>Spielen</b>
            </Link>
            <Link
              to={"/regeln/"}
              className="button is-info is-fullwidth is-large"
            >
              <b>Regeln</b>
            </Link>
            <Link
              className="button is-fullwidth is-large is-success"
              to={"/bedienung/"}
            >
              <b>Bedienung</b>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default IndexPage
